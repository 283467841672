/**
 * This bootstrap file is used for both frontend and backend
 */

 import _ from 'lodash'
 import axios from 'axios'
 import Swal from 'sweetalert2';
 // import $ from 'jquery';
 // import 'popper.js'; // Required for BS4
 // import 'bootstrap';
 
 /**
  * We'll load jQuery and the Bootstrap jQuery plugin which provides support
  * for JavaScript based Bootstrap features such as modals and tabs. This
  * code may be modified to fit the specific needs of your application.
  */
 
 // window.$ = window.jQuery = $;
 window.Swal = Swal;
 window._ = _; // Lodash
 
 /**
  * We'll load the axios HTTP library which allows us to easily issue requests
  * to our Laravel back-end. This library automatically handles sending the
  * CSRF token as a header based on the value of the "XSRF" token cookie.
  */
 
 window.axios = require('axios');
 
 window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
 
 /**
  * Echo exposes an expressive API for subscribing to channels and listening
  * for events that are broadcast by Laravel. Echo and event broadcasting
  * allows your team to easily build robust real-time web applications.
  */
 
 // import Echo from 'laravel-echo';
 
 // window.Pusher = require('pusher-js');
 
 // window.Echo = new Echo({
 //     broadcaster: 'pusher',
 //     key: process.env.MIX_PUSHER_APP_KEY,
 //     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
 //     encrypted: true
 // });
 
 
 // -------------
 // Core
 import tinymce from 'tinymce/tinymce'
 import 'tinymce/themes/modern/theme'
 
 // Plugins
 import 'tinymce/plugins/advlist'
 import 'tinymce/plugins/anchor'
 import 'tinymce/plugins/autolink'
 import 'tinymce/plugins/autoresize'
 import 'tinymce/plugins/autosave'
 import 'tinymce/plugins/bbcode'
 import 'tinymce/plugins/charmap'
 import 'tinymce/plugins/code'
 import 'tinymce/plugins/codesample'
 import 'tinymce/plugins/colorpicker'
 import 'tinymce/plugins/contextmenu'
 import 'tinymce/plugins/directionality'
 import 'tinymce/plugins/emoticons'
 import 'tinymce/plugins/fullpage'
 import 'tinymce/plugins/fullscreen'
 import 'tinymce/plugins/help'
 import 'tinymce/plugins/hr'
 import 'tinymce/plugins/image'
 import 'tinymce/plugins/imagetools'
 import 'tinymce/plugins/importcss'
 import 'tinymce/plugins/insertdatetime'
 import 'tinymce/plugins/legacyoutput'
 import 'tinymce/plugins/link'
 import 'tinymce/plugins/lists'
 import 'tinymce/plugins/media'
 import 'tinymce/plugins/nonbreaking'
 import 'tinymce/plugins/noneditable'
 import 'tinymce/plugins/pagebreak'
 import 'tinymce/plugins/paste'
 import 'tinymce/plugins/preview'
 import 'tinymce/plugins/print'
 import 'tinymce/plugins/save'
 import 'tinymce/plugins/searchreplace'
 import 'tinymce/plugins/spellchecker'
 import 'tinymce/plugins/tabfocus'
 import 'tinymce/plugins/table'
 import 'tinymce/plugins/template'
 import 'tinymce/plugins/textcolor'
 import 'tinymce/plugins/textpattern'
 import 'tinymce/plugins/toc'
 import 'tinymce/plugins/visualblocks'
 import 'tinymce/plugins/visualchars'
 import 'tinymce/plugins/wordcount'
 
 window.tinymce = tinymce;
 
 // Split JS
 import splitjs from 'split.js'
 window.SplitJS = splitjs;
 
 // Lazy load
 import LazyLoad from "vanilla-lazyload";
 window.LazyLoad = LazyLoad;
 
 // Photoswipe
 import Photoswipe from "photoswipe";
 import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default'
 window.PhotoSwipe = Photoswipe;
 window.PhotoSwipeUI_Default = PhotoSwipeUI_Default;
 
 // AnimeJS
 import anime from 'animejs/lib/anime.es.js';
 window.anime = anime;